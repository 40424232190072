<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <b-pagination
              v-model="curPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              limit="15"
          ></b-pagination>

        </b-col>
        <b-col cols="4"><span style="font-size: 10px">последнее обновление: {{lastUpdate}}</span></b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
              :items="items"
              :fields="fields"
              :per-page="perPage"
              :current-page="curPage"
              show-empty
              small
              headVariant="light"
              bordered
              responsive
              hover
              :busy="isLoading"
              tbody-class="acuvue-table"
              style="width: 99%"
              thead-class="acuvue-thead"
          >
            <template #table-busy>
              <div class="text-center text-secondary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </template>
            <template #thead-top="">
              <b-tr>
                <b-th colspan="3"></b-th>
                <b-th colspan="3" class="text-center">Остатки</b-th>
                <b-th colspan="2" class="text-center"></b-th>
              </b-tr>
            </template>
            <template #empty="">
              <div role="alert" aria-live="polite"><div class="text-center my-2">Список пуст.</div></div>
            </template>


            <template #cell(art_wb)="{item}">
              {{item.art_wb}}({{item.barcode}})
            </template>



          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
              v-model="curPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              limit="15"
          ></b-pagination>

        </b-col>
      </b-row>
    </b-col>

  </b-row>

</template>

<style>
.acuvue-thead th div {
  font-size: 12px;
}
</style>
<style scoped>
a {
  color: #111;
  font-weight: bold;
}
div {
  font-size: 12px;
}
</style>

<script>
// import _ from "lodash"
// import dayjs from 'dayjs'
// import weekOfYear from "./node_modules/dayjs/plugin/weekOfYear.js"
// import './node_modules/dayjs/locale/ru.js'

export default {
  name: "hydra6-table-page",
  data() {
    return {
      items: [],
      fields: [
        { key: 'seller', label: 'Продавец', sortable: false, sortDirection: 'desc' },
        { key: 'dio', label: 'Линзы', sortable: true, sortDirection: 'desc' },
        { key: 'art_wb', label: 'Артикул', sortable: true, sortDirection: 'desc' },
        { key: 'balances.mskTurk', label: 'МСК', sortable: true, sortDirection: 'desc' },
        { key: 'balances.spbTurk', label: 'СПБ', sortable: true, sortDirection: 'desc' },
        { key: 'wbData.qty', label: 'на WB', sortable: true, sortDirection: 'desc' },
        { key: 'wbData.price', label: 'Цена вб', sortable: true, sortDirection: 'desc' },
        { key: 'wbData.promoTextCard', label: 'Промо', sortable: false, sortDirection: 'desc' },
      ],
      isLoading: false,
      curPage: 1,
      totalRows: 100,
      perPage: 50,
      lastUpdate:""

    };
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});

    this.getReport()
  },
  methods: {
    getReport() {
      this.isLoading=true
      fetch(`${process.env.VUE_APP_SERVER_API}/hydra-table/`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            // console.log(resp)
            if (!resp.err) {
              this.items = resp.items //_.orderBy(resp.items, 'delta', 'asc')
              this.isLoading=false
              this.totalRows=resp.items.length
              this.lastUpdate=resp.lastUpdate
            }
            // if (!resp.status) this.alertMsg('Ошибка загрузки выкупов', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          })
    },

  },
  computed: {

  },
};
</script>